.root {
}

.inputError {
  border-bottom: var(--borderErrorField);
}

.customButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  margin: 12px 0px;
}

.customButtonDisabled {
  display: inline-block;
  padding: 10px 20px;
  background-color: grey;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  margin: 12px 0px;
  cursor: not-allowed;
  pointer-events: none;
}
