@import '../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--colorFail);

  /* Margin */
  margin-top: 0;
  margin-bottom: 32px;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.fieldsWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &:nth-child(2) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: row;
  }

  /* Margins */
  composes: formMargins;
  &:last-child {
    margin-bottom: 32px;
  }

  & .field {
    flex: 1;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.field {
  composes: formMargins;
}

.submitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Height */
    min-height: 72px;
    height: 72px;

    /* Layout */
    flex: 1;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}

.inputStyle {
  border: 1px solid var(--marketplaceColor) !important;
  padding-left: 10px;
  margin-top: 10px;
}

.customLabelInput {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%; /* 17.44px */
  letter-spacing: -0.32px;
  text-transform: initial;
}

.landingpageSubmitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 109%; /* 17.44px */
  letter-spacing: -0.32px;
  text-transform: initial;

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}

.radioFlex {
  display: flex;
  gap: 20px;
  margin: 0px;
}

.customItem {
  color: black !important;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  padding-top: 5px;
}

.autofillin {
  padding: 16px;
  background-color: #f1f1f1;
  color: #5b5959;
  line-height: normal;
  margin-bottom: 40px;
}

.autofillinFlex {
  display: flex;
  gap: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 12px;
}

.fieldSelect {
  padding: 12px 0px;
}

.select {
  background-color: black !important;
  border-bottom: 1px solid black !important;
  color: white !important;
  padding: 12px !important;
  background-image: none !important;
  text-align: center;
}

input::placeholder {
  text-transform: lowercase !important;
}

.paddingTop {
  padding-top: 20px;
}

.divider {
  position: relative;
  text-align: center;
  width: 100%;
  margin: 20px 0;
  color: #9F9F9F;
}

.divider::before, .divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 40%;
  border-top: 1px solid #9F9F9F; /* Adjust color and thickness */
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  position: relative;
  z-index: 1; /* Ensures the text is above the lines */
}

